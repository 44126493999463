<template>
  <div class="tags">
    <template
      v-for="(tag) in tagArray"
      :key="tag.id"
    >
      <tag
        :tag-name="tag.name"
        font-color="black"
        :delete-action="isDelete"
        @delete="deleteTagAction(tag)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ICbTag } from '~/interfaces'

const { tagArray, isDelete } = defineProps({
  tagArray: {
    type: Array as PropType<ICbTag[]>,
    required: true,
  },

  isDelete: {
    type: Boolean,
    default: true,
  },
})

const searchStore = useSearchStore()
const deleteTagAction = (tag: ICbTag) => {
  searchStore.removeSelectedTag(tag)
}
</script>

<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(0.5rem, -1.8704rem + 3.7037vw, 1rem);
}
</style>
